import React from 'react'

const MessageIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.66666 10.8083V5.82496C1.66666 3.52496 3.53332 1.66663 5.83332 1.66663H14.1667C16.4667 1.66663 18.3333 3.52496 18.3333 5.82496V11.6416C18.3333 13.9333 16.4667 15.7916 14.1667 15.7916H12.9167C12.6583 15.7916 12.4083 15.9166 12.25 16.125L11 17.7833C10.45 18.5166 9.54999 18.5166 8.99999 17.7833L7.74999 16.125C7.61666 15.9416 7.31666 15.7916 7.08332 15.7916H5.83332C3.53332 15.7916 1.66666 13.9333 1.66666 11.6416V10.8083Z" fill="#A6A7B2"/>
        <path d="M14.1667 7.29163H5.83334C5.49168 7.29163 5.20834 7.00829 5.20834 6.66663C5.20834 6.32496 5.49168 6.04163 5.83334 6.04163H14.1667C14.5083 6.04163 14.7917 6.32496 14.7917 6.66663C14.7917 7.00829 14.5083 7.29163 14.1667 7.29163Z" fill="white"/>
        <path d="M10.8333 11.4584H5.83334C5.49168 11.4584 5.20834 11.175 5.20834 10.8334C5.20834 10.4917 5.49168 10.2084 5.83334 10.2084H10.8333C11.175 10.2084 11.4583 10.4917 11.4583 10.8334C11.4583 11.175 11.175 11.4584 10.8333 11.4584Z" fill="white"/>
    </svg>

  )
}

export default MessageIcon
