import React from 'react'

const PlaylistIcon = ({onClick}) => {
    return (
        <svg onClick={onClick} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.625 15L15.625 5C15.625 4.65833 15.9083 4.375 16.25 4.375C16.5917 4.375 16.875 4.65833 16.875 5L16.875 15C16.875 15.3417 16.5917 15.625 16.25 15.625C15.9083 15.625 15.625 15.3417 15.625 15Z" fill="#A6A7B2"/>
            <path d="M17.7083 12.5L17.7083 7.5C17.7083 7.15833 17.9916 6.875 18.3333 6.875C18.675 6.875 18.9583 7.15833 18.9583 7.5L18.9583 12.5C18.9583 12.8417 18.675 13.125 18.3333 13.125C17.9916 13.125 17.7083 12.8417 17.7083 12.5Z" fill="#A6A7B2"/>
            <path d="M13.4167 5.5L13.4167 14.5C13.4167 15.6634 12.3288 16.75 10.8334 16.75L5.00002 16.75C3.50462 16.75 2.41669 15.6634 2.41669 14.5L2.41669 5.5C2.41669 4.33659 3.50462 3.25 5.00002 3.25L10.8334 3.25C12.3288 3.25 13.4167 4.33659 13.4167 5.5Z" stroke="#A6A7B2" stroke-width="1.5"/>
            <path d="M9.83976 9.42308L8.53976 9.42308L8.53976 8.07692C8.53976 7.76154 8.27823 7.5 7.96284 7.5C7.64746 7.5 7.38592 7.76154 7.38592 8.07692L7.38592 9.42308L5.99361 9.42308C5.67823 9.42308 5.41669 9.68462 5.41669 10C5.41669 10.3154 5.67823 10.5769 5.99361 10.5769L7.38592 10.5769L7.38592 11.9231C7.38592 12.2385 7.64746 12.5 7.96284 12.5C8.27823 12.5 8.53976 12.2385 8.53976 11.9231L8.53976 10.5769L9.83976 10.5769C10.1551 10.5769 10.4167 10.3154 10.4167 10C10.4167 9.68462 10.1551 9.42308 9.83976 9.42308Z" fill="#A6A7B2"/>
        </svg>
    )
}

export default PlaylistIcon