import React from 'react'

const StartFullIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.90338 8.81625L7.38486 7.90366L8.72931 5.62218L10.0738 3.3407L12.7626 7.90366L17.2441 8.81625L13.6589 12.4666L14.5552 17.0296L10.0738 14.7481L5.59227 17.0296L6.48857 12.4666L2.90338 8.81625Z" fill="#FFEF9C"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.38521 7.90365L10.0741 3.34069L12.763 7.90365L17.2445 8.81624L13.6593 12.4666L14.5556 17.0296L10.0741 14.7481L5.59261 17.0296L6.48891 12.4666L2.90373 8.81624L7.38521 7.90365ZM5.07717 12.8898L1.9736 9.72976C1.63875 9.38882 1.51727 8.892 1.65701 8.43501C1.79675 7.97802 2.17532 7.63412 2.64358 7.53876L6.55656 6.74193L8.9509 2.67881C9.18527 2.2811 9.61247 2.03699 10.0741 2.03699C10.5357 2.03699 10.9629 2.2811 11.1973 2.67881L13.5916 6.74193L17.5046 7.53876C17.9729 7.63412 18.3514 7.97802 18.4912 8.43501C18.6309 8.892 18.5094 9.38882 18.1746 9.72976L15.071 12.8898L15.8348 16.7783C15.931 17.2678 15.7394 17.7692 15.3414 18.0698C14.9433 18.3705 14.4086 18.4177 13.9641 18.1914L10.0741 16.211L6.18408 18.1914C5.73955 18.4177 5.20485 18.3705 4.80682 18.0698C4.40879 17.7692 4.21721 17.2678 4.31336 16.7783L5.07717 12.8898Z" fill="#E89B05"/>
        </svg>
    )
}

export default StartFullIcon
