import React from 'react'

const TwitterIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.7731 8.08875C19.7799 8.26364 19.7821 8.43845 19.7821 8.61334C19.7821 13.9155 16.0523 20.0366 9.2317 20.0366C7.13654 20.0366 5.18834 19.3689 3.54688 18.2321C3.83703 18.2639 4.13172 18.2878 4.43092 18.2878C6.16809 18.2878 7.76811 17.6438 9.03726 16.5627C7.41464 16.5388 6.0445 15.3703 5.57196 13.7804C5.79881 13.8281 6.03243 13.852 6.27133 13.852C6.60822 13.852 6.9353 13.8044 7.24882 13.709C5.55084 13.3433 4.2719 11.7217 4.2719 9.7741C4.2719 9.75026 4.2719 9.74226 4.2719 9.72636C4.77232 10.0205 5.34509 10.2032 5.95329 10.2271C4.95696 9.50369 4.30203 8.27157 4.30203 6.88044C4.30203 6.1491 4.48441 5.45748 4.80547 4.86128C6.63384 7.29377 9.36737 8.89161 12.4491 9.05855C12.3858 8.76442 12.3533 8.45448 12.3533 8.14445C12.3533 5.92659 14.0136 4.13 16.0621 4.13C17.1285 4.13 18.0917 4.61498 18.7677 5.39402C19.6141 5.21913 20.4069 4.88528 21.1244 4.42422C20.8463 5.36224 20.2592 6.14906 19.492 6.64192C20.2426 6.54653 20.9586 6.33206 21.6226 6.01409C21.1244 6.81697 20.4974 7.52435 19.7731 8.08875Z" fill="white"/>
        </svg>
    )
}

export default TwitterIcon
