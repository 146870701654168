import React from 'react'

const ChartIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.7" d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2Z" fill="#E5EAF1"/>
            <path d="M16.42 7.8099V16.1899C16.42 16.8299 15.9 17.3499 15.26 17.3499C14.61 17.3499 14.09 16.8299 14.09 16.1899V7.8099C14.09 7.1699 14.61 6.6499 15.26 6.6499C15.9 6.6499 16.42 7.1699 16.42 7.8099Z" fill="#A5B4CB"/>
            <path d="M9.90996 12.93V16.19C9.90996 16.83 9.38996 17.35 8.73996 17.35C8.09996 17.35 7.57996 16.83 7.57996 16.19V12.93C7.57996 12.29 8.09996 11.77 8.73996 11.77C9.38996 11.77 9.90996 12.29 9.90996 12.93Z" fill="#A5B4CB"/>
        </svg>
    )
}

export default ChartIcon
