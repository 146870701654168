import React from 'react'

const EpisodesIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.1201 9.12011C18.7301 9.12011 18.4201 9.43011 18.4201 9.82011V11.4001C18.4201 14.9401 15.5401 17.8201 12.0001 17.8201C8.46005 17.8201 5.58005 14.9401 5.58005 11.4001V9.81011C5.58005 9.42011 5.27005 9.11011 4.88005 9.11011C4.49005 9.11011 4.18005 9.42011 4.18005 9.81011V11.3901C4.18005 15.4601 7.31005 18.8101 11.3001 19.1701V21.3001C11.3001 21.6901 11.6101 22.0001 12.0001 22.0001C12.3901 22.0001 12.7001 21.6901 12.7001 21.3001V19.1701C16.6801 18.8201 19.8201 15.4601 19.8201 11.3901V9.81011C19.8101 9.43011 19.5001 9.12011 19.1201 9.12011Z" fill="#E5EAF1"/>
            <path d="M12 2C9.55996 2 7.57996 3.98 7.57996 6.42V11.54C7.57996 13.98 9.55996 15.96 12 15.96C14.44 15.96 16.42 13.98 16.42 11.54V6.42C16.42 3.98 14.44 2 12 2ZM13.31 8.95C13.24 9.21 13.01 9.38 12.75 9.38C12.7 9.38 12.65 9.37 12.6 9.36C12.21 9.25 11.8 9.25 11.41 9.36C11.09 9.45 10.78 9.26 10.7 8.95C10.61 8.64 10.8 8.32 11.11 8.24C11.7 8.08 12.32 8.08 12.91 8.24C13.21 8.32 13.39 8.64 13.31 8.95ZM13.84 7.01C13.75 7.25 13.53 7.39 13.29 7.39C13.22 7.39 13.16 7.38 13.09 7.36C12.39 7.1 11.61 7.1 10.91 7.36C10.61 7.47 10.27 7.31 10.16 7.01C10.05 6.71 10.21 6.37 10.51 6.27C11.47 5.92 12.53 5.92 13.49 6.27C13.79 6.38 13.95 6.71 13.84 7.01Z" fill="#A5B4CB"/>
        </svg>
    )
}

export default EpisodesIcon



