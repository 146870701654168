import React from 'react'

const ViewIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10 8.74995C9.31083 8.74995 8.75 9.31078 8.75 9.99995C8.75 10.6891 9.31083 11.2499 10 11.2499C10.6892 11.2499 11.25 10.6891 11.25 9.99995C11.25 9.31078 10.6892 8.74995 10 8.74995ZM10 12.9166C8.39167 12.9166 7.08333 11.6083 7.08333 9.99995C7.08333 8.39161 8.39167 7.08328 10 7.08328C11.6083 7.08328 12.9167 8.39161 12.9167 9.99995C12.9167 11.6083 11.6083 12.9166 10 12.9166ZM18.2233 9.58495C17.6908 8.65828 14.755 4.01328 9.77417 4.16911C5.16833 4.28661 2.48833 8.34495 1.77667 9.58495C1.63 9.84245 1.63 10.1574 1.77667 10.4149C2.30167 11.3291 5.135 15.8333 10.0208 15.8333C10.0883 15.8333 10.1567 15.8324 10.2258 15.8308C14.8317 15.7133 17.5117 11.6549 18.2233 10.4149C18.37 10.1574 18.37 9.84245 18.2233 9.58495Z" fill="#A6A7B2"/>
        </svg>
    )
}

export default ViewIcon
