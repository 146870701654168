import React from 'react'

const UploadIcon = () => {
    return (
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.9997 5.49976C26.7315 5.49976 30.9005 8.54492 32.4038 12.9156C36.8753 13.5224 40.333 17.3651 40.333 21.9998C40.333 24.2383 39.519 26.3924 38.0413 28.0663C37.6783 28.4751 37.1742 28.6859 36.6663 28.6859C36.2355 28.6859 35.8028 28.5356 35.4527 28.2276C34.6955 27.5548 34.6222 26.3979 35.2932 25.6371C36.1786 24.6361 36.6663 23.3418 36.6663 21.9998C36.6663 18.9674 34.1987 16.4998 31.1663 16.4998H30.983C30.1103 16.4998 29.3587 15.8838 29.1863 15.0276C28.5007 11.6323 25.4793 9.16642 21.9997 9.16642C18.5218 9.16642 15.4987 11.6323 14.8148 15.0276C14.6425 15.8838 13.889 16.4998 13.0163 16.4998H12.833C9.80065 16.4998 7.33299 18.9674 7.33299 21.9998C7.33299 23.3418 7.82065 24.6361 8.70799 25.6371C9.37715 26.3979 9.30565 27.5548 8.54665 28.2276C7.78765 28.8986 6.62899 28.8234 5.95982 28.0663C4.48032 26.3924 3.66632 24.2383 3.66632 21.9998C3.66632 17.3651 7.12399 13.5224 11.5955 12.9156C13.1007 8.54492 17.2697 5.49976 21.9997 5.49976ZM20.7259 20.6799C21.4482 19.9906 22.5904 19.9961 23.2962 20.7038L28.7962 26.2038C29.513 26.9206 29.513 28.0793 28.7962 28.7961C28.4387 29.1536 27.9694 29.3333 27.5 29.3333C27.0307 29.3333 26.5614 29.1536 26.2039 28.7961L23.8334 26.4256V36.6666C23.8334 37.6804 23.012 38.4999 22 38.4999C20.988 38.4999 20.1667 37.6804 20.1667 36.6666V26.3193L17.7742 28.6293C17.0464 29.3351 15.8859 29.3113 15.1819 28.5834C14.4779 27.8538 14.498 26.6951 15.2259 25.9911L20.7259 20.6799Z" fill="#A6A7B2"/>
        </svg>

    )
}

export default UploadIcon
