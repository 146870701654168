import React from 'react'

const RatingIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.83694 2.08292L8.80082 4.00076C8.79566 4.26389 8.96593 4.61305 9.18264 4.76992L10.4622 5.72125C11.2826 6.32848 11.1485 7.07234 10.1681 7.37596L8.50156 7.88704C8.22293 7.97307 7.92883 8.27163 7.85659 8.54994L7.45929 10.0377C7.14455 11.2116 6.36028 11.328 5.71015 10.2957L4.80204 8.85356C4.63693 8.59042 4.24479 8.39307 3.93521 8.40825L2.21187 8.49428C0.978694 8.555 0.627834 7.85668 1.43275 6.93571L2.45437 5.77185C2.64528 5.55426 2.733 5.14944 2.64528 4.87619L2.11899 3.23666C1.81457 2.27521 2.3615 1.74388 3.33668 2.05762L4.8588 2.54846C5.11679 2.62943 5.50376 2.57377 5.72047 2.4169L7.30966 1.29352C8.17134 0.691346 8.85758 1.04556 8.83694 2.08292Z" fill="#FFCE73"/>
        </svg>
    )
}

export default RatingIcon