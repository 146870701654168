import React from 'react'

const PauseIcon = ({className}) => {
    return (
        <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3329 12.5C13.3329 12.9583 12.9579 13.3333 12.4996 13.3333C12.0413 13.3333 11.6663 12.9583 11.6663 12.5V7.49996C11.6663 7.04163 12.0413 6.66663 12.4996 6.66663C12.9579 6.66663 13.3329 7.04163 13.3329 7.49996V12.5ZM8.33293 12.5C8.33293 12.9583 7.95793 13.3333 7.49959 13.3333C7.04126 13.3333 6.66626 12.9583 6.66626 12.5V7.49996C6.66626 7.04163 7.04126 6.66663 7.49959 6.66663C7.95793 6.66663 8.33293 7.04163 8.33293 7.49996V12.5ZM9.99959 1.66663C5.40459 1.66663 1.66626 5.40496 1.66626 9.99996C1.66626 14.595 5.40459 18.3333 9.99959 18.3333C14.5954 18.3333 18.3329 14.595 18.3329 9.99996C18.3329 5.40496 14.5954 1.66663 9.99959 1.66663Z" fill="#FF754C"/>
            <rect x="6.66666" y="6.66663" width="1.66667" height="6.66667" rx="0.833333" fill="white"/>
            <rect x="11.6667" y="6.66663" width="1.66667" height="6.66667" rx="0.833333" fill="white"/>
        </svg>
    )
}

export default PauseIcon