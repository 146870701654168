import React from 'react'

const SubIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 7V8.13C18.68 8.04 18.35 8 18 8H6C5.65 8 5.32 8.04 5 8.13V7C5 5.9 5.9 5 7 5H17C18.1 5 19 5.9 19 7Z" fill="#A5B4CB"/>
            <path d="M16 3.51001V5H8V3.51001C8 2.68001 8.68001 2 9.51001 2H14.49C15.32 2 16 2.68001 16 3.51001Z" fill="#E5EAF1"/>
            <path d="M22 12V18C22 20.2 20.2 22 18 22H6C3.8 22 2 20.2 2 18V12C2 10.15 3.28 8.58 5 8.13C5.32 8.04 5.65 8 6 8H18C18.35 8 18.68 8.04 19 8.13C20.72 8.58 22 10.15 22 12Z" fill="#E5EAF1"/>
            <path d="M9 14.6167V13.1367C9 11.2267 10.35 10.4567 12 11.4067L13.28 12.1467L14.56 12.8867C16.21 13.8367 16.21 15.3967 14.56 16.3467L13.28 17.0867L12 17.8267C10.35 18.7767 9 17.9967 9 16.0967V14.6167Z" fill="#A5B4CB"/>
        </svg>
    )
}

export default SubIcon



