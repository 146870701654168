import React from 'react'

const CheckIcon = ({className}) => {
    return (
        <svg className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.17 8.05504L15.15 6.87004C14.955 6.64504 14.7975 6.22504 14.7975 5.92504V4.65004C14.7975 3.85504 14.145 3.20254 13.35 3.20254H12.075C11.7825 3.20254 11.355 3.04504 11.13 2.85004L9.94497 1.83004C9.42747 1.38754 8.57997 1.38754 8.05497 1.83004L6.87747 2.85754C6.65247 3.04504 6.22497 3.20254 5.93247 3.20254H4.63497C3.83997 3.20254 3.18747 3.85504 3.18747 4.65004V5.93254C3.18747 6.22504 3.02997 6.64504 2.84247 6.87004L1.82997 8.06254C1.39497 8.58004 1.39497 9.42004 1.82997 9.93754L2.84247 11.13C3.02997 11.355 3.18747 11.775 3.18747 12.0675V13.35C3.18747 14.145 3.83997 14.7975 4.63497 14.7975H5.93247C6.22497 14.7975 6.65247 14.955 6.87747 15.15L8.06247 16.17C8.57997 16.6125 9.42747 16.6125 9.95247 16.17L11.1375 15.15C11.3625 14.955 11.7825 14.7975 12.0825 14.7975H13.3575C14.1525 14.7975 14.805 14.145 14.805 13.35V12.075C14.805 11.7825 14.9625 11.355 15.1575 11.13L16.1775 9.94504C16.6125 9.42754 16.6125 8.57254 16.17 8.05504ZM12.12 7.58254L8.49747 11.205C8.39247 11.31 8.24997 11.37 8.09997 11.37C7.94997 11.37 7.80747 11.31 7.70247 11.205L5.88747 9.39004C5.66997 9.17254 5.66997 8.81254 5.88747 8.59504C6.10497 8.37754 6.46497 8.37754 6.68247 8.59504L8.09997 10.0125L11.325 6.78754C11.5425 6.57004 11.9025 6.57004 12.12 6.78754C12.3375 7.00504 12.3375 7.36504 12.12 7.58254Z" fill="#7B6DD7"/>
        </svg>
    )
}

export default CheckIcon
