import React from 'react'

const DashboardIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.9999 12C21.9999 17.523 17.5229 22 11.9999 22C6.47688 22 1.99988 17.523 1.99988 12C1.99988 6.478 6.47688 2 11.9999 2C17.5229 2 21.9999 6.478 21.9999 12Z" fill="#E5EAF1"/>
            <path d="M15.8598 8.70481L14.2398 13.8248C14.1798 14.0348 14.0098 14.2048 13.7998 14.2658L8.69984 15.8648C8.35984 15.9758 8.02984 15.6448 8.13984 15.3048L9.73984 10.1748C9.79984 9.96481 9.96984 9.80481 10.1798 9.73481L15.2998 8.13481C15.6498 8.02481 15.9698 8.35481 15.8598 8.70481Z" fill="#A5B4CB"/>
        </svg>
    )
}

export default DashboardIcon



