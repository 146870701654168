import React from 'react'

const CubeIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.3301 5.68003L13.0601 2.30003C12.4001 1.94003 11.6001 1.94003 10.9401 2.30003L4.67005 5.68003C4.21005 5.93003 3.93005 6.41003 3.93005 6.96003C3.93005 7.50003 4.21005 7.99003 4.67005 8.24003L10.9401 11.62C11.2701 11.8 11.6401 11.89 12.0001 11.89C12.3601 11.89 12.7301 11.8 13.0601 11.62L19.3301 8.24003C19.7901 7.99003 20.0701 7.51003 20.0701 6.96003C20.0701 6.41003 19.7901 5.93003 19.3301 5.68003Z" fill="#A5B4CB"/>
            <path d="M9.91 12.79L4.07 9.87C3.62 9.65 3.1 9.67 2.68 9.93C2.25 10.2 2 10.65 2 11.15V16.66C2 17.61 2.53 18.47 3.38 18.9L9.21 21.82C9.41 21.92 9.63 21.97 9.85 21.97C10.11 21.97 10.37 21.9 10.6 21.76C11.03 21.5 11.28 21.04 11.28 20.54V15.03C11.29 14.07 10.76 13.21 9.91 12.79Z" fill="#E5EAF1"/>
            <path d="M21.32 9.9299C20.89 9.6699 20.37 9.6399 19.93 9.8699L14.1 12.7899C13.25 13.2199 12.72 14.0699 12.72 15.0299V20.5399C12.72 21.0399 12.97 21.4999 13.4 21.7599C13.63 21.8999 13.89 21.9699 14.15 21.9699C14.37 21.9699 14.59 21.9199 14.79 21.8199L20.62 18.8999C21.47 18.4699 22 17.6199 22 16.6599V11.1499C22 10.6499 21.75 10.1999 21.32 9.9299Z" fill="#E5EAF1"/>
        </svg>
    )
}

export default CubeIcon


